import React, { useState } from 'react';
import Swal from 'sweetalert2';
import Footer from './Footer';

const Register = () => {
  const [inpval, setInp] = useState({
    name: "",
    email: "",
    age: "",
    mobile: "",
    address: "",
    description: "",
  });

  const setData = (e) => {
    const { name, value } = e.target;
    setInp((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const addinpdata = async (e) => {
    e.preventDefault();
    const { name, email, age, mobile, address, description } = inpval;

    if (!name || !email || !age || !mobile || !address || !description) {
      Swal.fire("All fields are required!", "", "error");
      return;
    }

    try {
      const res = await fetch("http://localhost:5000/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          age,
          mobile,
          address,
          description,
        }),
      });

      const data = await res.json();

      if (res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Data Submitted Successfully",
          showConfirmButton: false,
          timer: 1500,
        });

        // Construct WhatsApp message
        const whatsappMessage = `Hello, I have submitted the form with the following details:\n\n
          Name: ${name}\n
          Email: ${email}\n
          Age: ${age}\n
          Mobile: ${mobile}\n
          Address: ${address}\n
          Description: ${description}`;

        // Redirect to WhatsApp
        const whatsappUrl = `https://wa.me/919140474598?text=${encodeURIComponent(
          whatsappMessage
        )}`;
        window.open(whatsappUrl, "_blank");
      } else {
        Swal.fire("Error submitting data!", data.message || "Try again later", "error");
      }
    } catch (error) {
      Swal.fire("Error", "Something went wrong. Please try again later.", "error");
      console.error(error);
    }
  };

  return (
    <>
      <br />
      <center>
        <h2>Appointment</h2>
      </center>
      <div className="container">
        <form className="mt-5" onSubmit={addinpdata}>
          <div className="row">
            <div className="mb-3 col-lg-6 col-12">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                value={inpval.name}
                onChange={setData}
                name="name"
                className="form-control"
                id="name"
                required
              />
            </div>

            <div className="mb-3 col-lg-6 col-12">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                value={inpval.email}
                onChange={setData}
                name="email"
                className="form-control"
                id="email"
                required
              />
            </div>

            <div className="mb-3 col-lg-6 col-12">
              <label htmlFor="age" className="form-label">
                Age
              </label>
              <input
                type="number"
                value={inpval.age}
                onChange={setData}
                name="age"
                className="form-control"
                id="age"
                required
              />
            </div>

            <div className="mb-3 col-lg-6 col-12">
              <label htmlFor="mobile" className="form-label">
                Mobile
              </label>
              <input
                type="text"
                value={inpval.mobile}
                onChange={setData}
                name="mobile"
                className="form-control"
                id="mobile"
                required
              />
            </div>

            <div className="mb-3 col-lg-6 col-12">
              <label htmlFor="address" className="form-label">
                Address
              </label>
              <input
                type="text"
                value={inpval.address}
                onChange={setData}
                name="address"
                className="form-control"
                id="address"
                required
              />
            </div>

            <div className="mb-3 col-lg-12">
              <label htmlFor="description" className="form-label">
                Description
              </label>
              <textarea
                name="description"
                value={inpval.description}
                onChange={setData}
                className="form-control"
                id="description"
                rows="3"
                required
              ></textarea>
            </div>

            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
      <br />
      <Footer />
    </>
  );
};

export default Register;
